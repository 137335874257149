:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/bg-menu-mobile.jpg);
	--navbarbreak: 768px;
	--primary: #cab051;
	--secondary: #3f3f3e;
	--black: #3e3e3e;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--white: #fff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #331717;
	--li-blue: #daf0fb;
	--grey2: #7b7b7b;
	--red: #9a5252;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;

	/*Calendar colors*/
	--cal_not_available: #cab051;
	/*#cd7898;*/
	--disabled: #CCCCCC;
	/*rgba(86, 90, 92, .5);*/
	--available: #fff;
	--selected: #062e5f;
	--selection_range: #4D6E96;
	--selected_border: #fff;
}